import { useState } from 'react'
import {
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
  faPython,
} from '@fortawesome/free-brands-svg-icons'

import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    return (
        <>
        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                    strArray = {['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']} 
                    idx = {15}/>
                    
                </h1>
                <p> I'm very ambitious full stack developer looking for a role in
            established company with the opportunity to work with the latest
            technologies on challenging and diverse projects.</p>
                <p>I'm quietly confident, naturally curious, and perpetually working on
            improving my skills, a family person, and an avid reader. </p>
                <p>My objective in every project is to understand the project's objectives and create a product that engages the
target audience, strategic planning in any development life cycle and possess the
aptitude to plan the design, development, and implementation phases strategically
and to help analyze information accurately and make logical, data-driven decisions
that help in maximizing efficiency.</p>

            </div>
            <div className="stage-cube-cont">
                <div className="cubespinner">
                    <div className="face1">
                        <FontAwesomeIcon icon={faPython} color="#DD0031" />
                    </div>
                    <div className="face2">
                        <FontAwesomeIcon icon={faHtml5} color="#F06529" />
                    </div>
                    <div className="face3">
                        <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
                    </div>
                    <div className="face4">
                        <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                    </div>
                    <div className="face5">
                        <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
                    </div>
                    <div className="face6">
                        <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
                    </div>
                </div>
            </div>

        </div>
    
        
    </>  

    )
}

export default About